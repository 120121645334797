import React, { useState } from 'react';
import { Typography } from '@ds';
import clsx from 'clsx';

type Range<F extends number, T extends number> =
  | Exclude<Enumerate<T>, Enumerate<F>>
  | T;
type Enumerate<
  N extends number,
  Acc extends number[] = []
> = Acc['length'] extends N
  ? Acc[number]
  : Enumerate<N, [...Acc, Acc['length']]>;

type ValidSize = Range<2, 13>;

type TextToggleItem = {
  label: string;
  onClick: () => void;
};

interface TextToggleProps {
  equalPartitions?: boolean;
  items: TextToggleItem[];
  size?: ValidSize;
}

const validateSize = (size: number): size is ValidSize => {
  return size >= 2 && size <= 13;
};

export const TextToggle: React.ComponentType<TextToggleProps> = ({
  equalPartitions = false,
  items,
  size = 2,
}) => {
  if (!validateSize(size)) {
    throw new Error('Size must be between 2 and 13');
  }

  const [selectedLabel, setSelectedLabel] = useState<string | null>(null);

  const handleItemClick = (itemFunction: () => void, label: string) => {
    itemFunction();
    setSelectedLabel(label);
  };

  return (
    <div
      // eslint-disable-next-line tailwindcss/no-custom-classname
      className={clsx(
        `w-fit rounded-lg border border-gray-200`,
        `grid-cols-${size}`,
        equalPartitions ? 'grid-cols' : 'flex'
      )}
    >
      {items.map((item) => {
        return (
          <div
            key={item.label}
            className={clsx(
              `border-r border-r-gray-200 px-3 py-2 first:rounded-l-lg last:rounded-r-lg last:border-none`,
              selectedLabel === item.label && 'bg-gray-100'
            )}
            onClick={() => {
              handleItemClick(item.onClick, item.label);
            }}
          >
            <Typography>{item.label}</Typography>
          </div>
        );
      })}
    </div>
  );
};
